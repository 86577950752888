<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">About me</h1>
        <p>
          I am a software developer and passionate lucid dreamer. I have developed the NiteLite app on my own to help you guys become lucid inside your dreams.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",

  data: () => ({}),
};
</script>
<style>
</style>