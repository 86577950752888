import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#30879F',
            secondary: '#152E35',
            accent: '#41D5FF',
            error: '#b71c1c',
          },
        },
      },
});
