<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">LucidNite</h1>

        <p class="subheading font-weight-regular">
          NiteLite is a lucid dreaming app designed to help you become aware inside your dreams.
        </p>
        <!-- or join our
          <a
            href="https://community.vuetifyjs.com"
            target="_blank"
          >Discord Community</a>
        </p-->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'MainPage',

    data: () => ({
  }),
  }
</script>
<style>
</style>
