<template>
  <v-container>
    <v-row class="text-center">
      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">About me</h1>
        <p>
          Terms & Conditions
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Terms",

  data: () => ({}),
};
</script>
<style>
</style>