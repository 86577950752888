import Vue from 'vue'
import VueRouter from 'vue-router'

import MainPage from '../components/MainPage'
import About from '../components/About'
import Contact from '../components/Contact'
import Terms from '../components/Terms'
import Privacy from '../components/Privacy'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPage
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: Privacy
  },
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
