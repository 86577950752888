<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <v-btn
          color="white"
          to="/"
          text
          class="my-2 text-none"
        >
          LucidNite
        </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-footer color="primary lighten-1" padless absolute>
      <v-row justify="center" no-gutters>
        <v-btn
          color="white"
          to="/about"
          text
          class="my-2"
        >
          About
        </v-btn>
        <v-btn
          color="white"
          to="/privacy"
          text
          class="my-2"
        >
          Privacy Policy
        </v-btn>
        <v-btn
          color="white"
          to="/terms"
          text
          class="my-2"
        >
          Terms & Conditions
        </v-btn>
        <v-btn
          color="white"
          to="/contact"
          text
          class="my-2"
        >
          Contact
        </v-btn>
        <v-col class="primary lighten-2 py-4 text-center white--text" cols="12">
          <strong>LucidNite</strong> - {{ new Date().getFullYear() }}
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>


<script>
export default {
  name: "App",

  data: () => ({
  }),
};
</script>
<style>
.router-view {
  margin-top: 50%;
}
</style>
